import { makeAutoObservable, runInAction } from 'mobx'
import { openApi } from '../../../helpers/request'
import i from '../../../translations/i'
import { FormEvent } from 'react'
import { notification } from 'antd'
import __select from '../../../helpers/select_entity/__select.controller'

class CPLController {

    pagination = {
        limit: 10,
        page: 1,
        total: 0
    }

    list: any[] = []

    item: any = {
        id: 0,
        name: '',
        id_crm: '',
        conditions: []
    }

    selectedList: null|number = null;
    selectedLists: any[] = []

    properties: any = {}

    modalOpened = false
    productsModalSelectedIndex: number = -1
    selectedConditionProducts = []

    constructor() {
        makeAutoObservable(this)
    }

    getList() {
        let uri = `/custom_list_products?limit=${this.pagination.limit}&page=${this.pagination.page}`

        openApi('get', uri)
            .then(result => {
                this.list = result.data.map((el: any) => ({...el, key: el.id}))
                this.pagination.total = result.total
            })
            .catch(error => console.log(error.response))
    }

    setPage(page: number) {
        runInAction(() => this.pagination.page = page)
        this.getList()
    }

    getOne(id: null|number) {
        if(id && id > 0) {
            let uri = `/custom_list_products/${id}`

            openApi('get', uri)
                .then(result => {
                    this.item = result.data

                    if(result.data?.conditions) {
                        result.data.conditions.map((el: any, index: number) => {
                            let productIDs: any[] = []
                            let products: any[] = []

                            if(el.type === 'prop') {
                                this.getPropertyValues(el.label)
                            } else if(el.type === 'products') {
                                el.products.map((p: any) => {
                                    productIDs.push(p.product_id)
                                    products.push(p.product)
                                })
                            }

                            this.item.conditions[index].products = products
                            this.item.conditions[index].productIDs = productIDs
                        })
                    }
                })
                .catch(error => console.log(error.response))
        }
    }

    deleteOne(listID: number) {
        openApi('delete', `/custom_list_products/${listID}`)
            .then(() => {
                this.getList()
            })
            .catch(error => console.log(error.response))

    }

    save(type: string = 'list') {

        const data = new FormData()

        if(this.item.id > 0) {
            data.append('id', this.item.id)
        }
        if(this.item.id_crm === null) this.item.id_crm = ''

        data.append('name', this.item.name)
        data.append('id_crm', this.item.id_crm)

        if(this.item.conditions.length > 0) {
            this.item.conditions.map((el: any, key: number) => {
                console.log(el)
                if(el.id > 0) {
                    data.append(`conditions[${key}][id]`, el.id)
                }
                if(el.label !== '' && el.label !== null) {
                    data.append(`conditions[${key}][label]`, el.label)
                }
                data.append(`conditions[${key}][type]`, el.type)
                if(el.type === 'products') {
                    el.productIDs.map((pID: any, index: number) => {
                        data.append(`conditions[${key}][products][${index}]`, pID)
                    })
                } else {
                    data.append(`conditions[${key}][value]`, el.value && el.value !== '' ? el.value : __select.select.value)
                }
            })
        } else {
            return notification.error({
                message: i.t('def:errors.error'),
                description: i.t('def:Need to fill Conditions')
            })
        }

        openApi('post', '/custom_list_products', data)
            .then(result => {
                if(type === 'list' && this.item.id === 0) {
                    window.location.href = `/catalog/lists`
                } else {
                    if(this.item.id === 0 || this.item.id === null) {
                        this.item.id = result.data.id
                    }

                    if(type === 'group') {
                        this.selectedList = null
                        this.getList()
                    }

                    notification.success({
                        message: i.t('def:success.success'),
                        description: i.t('def:success.edit')
                    })
                }
            })
            .catch(error => {
                console.log(error.response)
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.data.message ?? 'error'
                })
            })
    }

    getPropertyValues(id: number) {
        if(this.properties[id]) return

        openApi('get', `/filters/${id}`)
            .then(result => {
                this.properties[id] = result.data
            })
            .catch(error => console.log(error.response))
    }

    selectList(id: number, multiple: boolean) {
        if(this.selectedLists.findIndex((el: any) => el === id) > -1) {
            this.selectedLists = this.selectedLists.filter((el: any) => el !== id)
        } else {
            if(multiple) {
                let array = this.selectedLists
                array.push(id)
                this.selectedLists = array
            } else {
                this.selectedLists = [id]
            }
        }
    }

    setEditedList(id: number) {
        this.selectedList = id

        if(id > 0) this.getOne(id)
        else {
            this.item = {
                id: 0,
                name: '',
                id_crm: '',
                conditions: []
            }
        }
    }

    addCondition() {
        this.item.conditions.push({
            id: 0,
            type: 'products',
            label: '',
            value: '',
            products: [],
            productIDs: [],
            category: []
        })
    }

    removeCondition(index: number) {
        this.item.conditions.splice(index, 1)
        if(this.properties?.[index]) delete this.properties[index]
    }

    changeConditionType(type: string, index: number) {
        this.item.conditions[index].type = type
        this.item.conditions[index].label = ''
        this.item.conditions[index].value = ''
    }

    toggleModal(index = -1) {
        if(!this.modalOpened) this.productsModalSelectedIndex = index

        if(this.item.conditions?.[this.productsModalSelectedIndex]?.type === 'products') {
            if(this.modalOpened){
                this.item.conditions[this.productsModalSelectedIndex].productIDs = __select.select.value_array
            } else {
                this.selectedConditionProducts = this.item.conditions[index].products
                __select.select.value_array = this.item.conditions[index].productIDs
            }
        }

        if(this.item.conditions?.[this.productsModalSelectedIndex]?.type === 'category') {
            if(this.modalOpened){
                this.item.conditions[this.productsModalSelectedIndex].category = __select.element
                this.item.conditions[this.productsModalSelectedIndex].value = __select.element?.id ?? ''
            } else {
                __select.select.value = this.item.conditions[index].category?.id ?? ''
            }
        }

        if(this.item.conditions?.[this.productsModalSelectedIndex]?.type === 'type') {
            if(this.modalOpened){
                this.item.conditions[this.productsModalSelectedIndex].value = __select.select.value ?? 0
            } else {
                __select.select.value = this.item.conditions[this.productsModalSelectedIndex].value ?? 0
            }
        }

        if(this.modalOpened) this.productsModalSelectedIndex = index
        this.modalOpened = !this.modalOpened
    }

    changeCharacteristic(id: number, index: number) {
        this.item.conditions[index].label = id
        this.item.conditions[index].value = ''
        this.getPropertyValues(id)
    }
}

export const __cpl = new CPLController()